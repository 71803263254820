<template>
  <div>
    <v-dialog v-if="!isMobile" v-model="authStore.showReloadDialog" max-width="900px" persistent>
      <v-card style="position: relative">
        <v-card-title
          class="light--text"
          :style="`background: linear-gradient(90deg, ${vuetify.theme.themes.light.primary} 0%, ${vuetify.theme.themes.light.secondary} 100%)`"
          style="position: sticky; top: 0; width: 100%; z-index: 1000"
        >
          <span class="white--text headline">Nova versão disponível</span>
        </v-card-title>
        <v-card-text class="py-4">
          <p class="ma-0 text-body-1">
            Uma nova versão está disponível. É altamente recomendado que você
            atualize o sistema.
            <br />
            <span v-if="!isMobile">
              Para isso, pressione os botões <b>{{ reloadCommand }}</b> ao mesmo
              tempo.
            </span>
            <span v-else>
              Para isso, pressione o botão <b>RECARREGAR</b> abaixo.
            </span>
          </p>
        </v-card-text>
        <div class="light" style="position: sticky; bottom: 0; width: 100%">
          <v-divider class="mx-4"></v-divider>
          <v-card-actions class="px-6 py-6">
            <v-spacer></v-spacer>
            <v-btn v-if="isMobile" text color="primary" @click="reloadPage()">
              RECARREGAR
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useVuetify } from "@/composables";
import { useAuthStore } from "@/stores";

const vuetify = useVuetify();

const authStore = useAuthStore();

const currentBrowser = computed(() => {
  let userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = "chrome";
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = "firefox";
  } else if (userAgent.match(/safari/i)) {
    browserName = "safari";
  } else if (userAgent.match(/opr\//i)) {
    browserName = "opera";
  } else if (userAgent.match(/edg/i)) {
    browserName = "edge";
  } else {
    browserName = "No browser detection";
  }

  return browserName;
});

const reloadCommand = computed(() => {
  if (currentBrowser.value === "safari") {
    return "Command + Option + R";
  } else {
    return "Shift + F5";
  }
});

const isMobile = computed(() => vuetify.breakpoint.mobile);

function reloadPage() {
  authStore.setReloadDialog(false);
  location.reload(true);
}
</script>

<style>
</style>
